import { Button, ConfirmModal, LoadingProgress, Modal } from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { SalesContext } from "../../context";
import { UploadFile } from "../../models";
import { AddSaleRecord } from "../../models/usecases/add-sale-record";
import { deleteUpload } from "../../services";
import { store } from "../../store";
import { StepSalesRecord } from "../../utils/JoyrideSteps";
import { JoyRide } from "../JoyRide";
import { RegisterSaleClientInfo } from "./RegisterSaleClientInfo";
import { RegisterSaleInfo } from "./RegisterSaleInfo";
import { RegisterSaleItensInfo } from "./RegisterSaleItensInfo";
import "./styles.scss";

function isObjectEmpty(obj: object): boolean {
	return Object.keys(obj).length === 0;
}

type Props = {
	onClose: () => void;
};

export const RegisterSaleModal = ({ onClose }: Props) => {
	const [steps, setSteps] = useState<string[]>([]);
	const [progress, setProgress] = useState<
		"selectClient" | "client" | "itens" | "sale"
	>("selectClient");
	const [selectedItens, setSelectedEquipments] = useState<any[]>([]);
	const [selectClient, setSelectClient] = useState<boolean>(true);
	const [showButton, setShowButton] = useState<boolean>(true);
	const [fileDeleted, setFileDeleted] = useState<any[]>([]);

	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const { handleNewSale, isLoading, onSearchParams } = useContext(SalesContext);

	const { t, i18n } = useTranslation();

	const userStore = store.getState().auth;

	const language = i18n.language;

	const clientForm = useForm<Pick<AddSaleRecord.Params, "client">>();
	const clientName = clientForm.watch("client")?.name || "";
	const clientEmail = clientForm.watch("client")?.email || "";

	const itensForm = useForm<Pick<AddSaleRecord.Params, "equipments">>();
	const itensFieldArray = useFieldArray({
		control: itensForm.control,
		name: "equipments"
	});

	const { fields } = itensFieldArray;

	const filesForm = useForm<UploadFile, "files">();
	const filesFieldArray = useFieldArray({
		control: filesForm.control,
		name: "files"
	});

	useEffect(() => {
		itensForm.setValue("equipments", selectedItens);
	}, [selectedItens]);

	const saleForm =
		useForm<
			Pick<
				AddSaleRecord.Params,
				"distributorOrder" | "saleDate" | "observation" | "files" | "equipments"
			>
		>();

	const handleOnSubmit = async () => {
		await clientForm.handleSubmit(() => {})();
		const clientErrors = !isObjectEmpty(clientForm.formState.errors);

		if (clientErrors) {
			return;
		} else if (progress === "client") {
			setProgress("itens");
			if (!steps.includes("itens")) {
				setSteps((step) => [...step, "client", "itens"]);
			}
			return;
		}

		if (progress === "itens") {
			if (selectedItens.length === 0) {
				toast.error("Adicione ao menos um item");
				return;
			}
			setProgress("sale");
			if (!steps.includes("sale")) {
				setSteps((step) => [...step, "sale"]);
			}
			return;
		}

		if (progress === "sale") {
			if (filesFieldArray.fields.length === 0) {
				filesForm.setError("files", {
					type: "required"
				});
			} else filesForm.clearErrors("files");
		}

		await saleForm.handleSubmit(() => {})();
		await itensForm.handleSubmit(() => {})();
		await filesForm.handleSubmit(() => {})();
		const saleErrors = !isObjectEmpty(saleForm.formState.errors);
		const itensErrors = !isObjectEmpty(itensForm.formState.errors);
		const filesErros = filesForm.getFieldState("files").error;

		if (fileDeleted.length > 0) {
			fileDeleted.forEach((file) => {
				deleteUpload(file);
			});
		}

		if (saleErrors || itensErrors || filesErros) {
			console.log(
				"saleErrors: ",
				saleErrors,
				"itensErrors: ",
				itensErrors,
				"filesErros: ",
				filesErros
			);
			filesErros && toast.error(t("newSaleRecordModal.toast.filesError"));
		} else {
			const equipments = itensForm.getValues().equipments;
			const files = filesFieldArray.fields;
			const data = {
				...clientForm.getValues(),
				...saleForm.getValues(),
				equipments: equipments.map((equipment) => {
					const dateUtc = new Date(equipment.installationDate);
					dateUtc.setHours(dateUtc.getHours() + 5);
					return {
						id: equipment.id,
						installationDate: dateUtc
					};
				}),
				files: files.map((file) => ({
					description: file.description,
					extension: file.type,
					url: file.url
				})),
				saleDate: new Date(saleForm.getValues().saleDate).toISOString()
			};
			handleNewSale(data, () => handleOnClose("submit"));
		}
	};

	const handleOnClose = (whence: string) => {
		whence !== "submit" && clearFields();
		clientForm.reset();
		itensForm.reset();
		saleForm.reset();
		onSearchParams("");
		onClose();
	};

	const clearFields = () => {
		filesFieldArray.fields.forEach((field) => {
			deleteUpload(field);
		});
	};

	const onChangeCompleteSteps = (
		item: "selectClient" | "client" | "itens" | "sale"
	) => {
		if (steps.includes(item)) {
			setProgress(item);
		}
	};

	const mainContainer = (
		progress: "selectClient" | "client" | "itens" | "sale"
	) => {
		switch (progress) {
			case "client":
				return (
					<RegisterSaleClientInfo
						clientForm={clientForm}
						nextItem={() => setProgress("client")}
						selectClient={selectClient}
						setSelectClient={setSelectClient}
						setShowButton={setShowButton}
					/>
				);
			case "selectClient":
				return (
					<RegisterSaleClientInfo
						clientForm={clientForm}
						nextItem={() => setProgress("client")}
						selectClient={selectClient}
						setSelectClient={setSelectClient}
						setShowButton={setShowButton}
					/>
				);
			case "itens":
				return (
					<RegisterSaleItensInfo
						client={{ name: clientName, email: clientEmail }}
						selectedItens={selectedItens}
						setSelectedEquipments={setSelectedEquipments}
					/>
				);
			case "sale":
				return (
					<RegisterSaleInfo
						client={{ name: clientName, email: clientEmail }}
						selectedItens={fields}
						setSelectedEquipments={setSelectedEquipments}
						saleForm={saleForm}
						itensForm={itensForm}
						filesForm={filesForm}
						fieldFiles={filesFieldArray}
						{...{ setFileDeleted }}
					/>
				);
			default:
				return (
					<RegisterSaleClientInfo
						clientForm={clientForm}
						nextItem={() => setProgress("client")}
						selectClient={selectClient}
						setSelectClient={setSelectClient}
						setShowButton={setShowButton}
					/>
				);
		}
	};

	return (
		<>
			<Modal.root>
				<Modal.header
					title={t("newSaleRecordModal.title")}
					onClose={() =>
						clientName ? setShowConfirmModal(true) : handleOnClose("cancel")
					}
				/>
				<Modal.body>
					<Modal.body.main>
						<div className="modal-register-sale">
							<div className="progress">
								<div
									className="progress-item client"
									onClick={() => onChangeCompleteSteps("client")}
								>
									<label htmlFor="client">
										{t("newSaleRecordModal.progressBar.client")}
									</label>
									<div
										id="client"
										className={
											progress === "client"
												? "active-first-step"
												: "completed-first-step"
										}
									/>
								</div>
								<div
									className="progress-item itens"
									onClick={() => onChangeCompleteSteps("itens")}
								>
									<label htmlFor="itens">
										{t("newSaleRecordModal.progressBar.itens")}
									</label>
									<div
										id="itens"
										className={
											progress === "itens" || progress === "sale"
												? progress === "itens"
													? "active-step"
													: "completed-step"
												: "uncompleted-step"
										}
									/>
								</div>
								<div
									className="progress-item sale"
									onClick={() => onChangeCompleteSteps("sale")}
								>
									<label htmlFor="sale">
										{t("newSaleRecordModal.progressBar.sale")}
									</label>
									<div
										id="sale"
										className={
											progress === "sale"
												? progress === "sale"
													? "active-last-step"
													: "completed-last-step"
												: "uncompleted-last-step"
										}
									/>
								</div>
							</div>
							{mainContainer(progress)}
						</div>
					</Modal.body.main>
					<Modal.body.actions>
						<div className="actions">
							{progress === "client" && showButton && (
								<Button
									title={t("newSaleRecordModal.changeSelection")}
									size="lg"
									variant="outline"
									style={{
										width:
											language === "en"
												? "160px"
												: language === "es"
													? "190px"
													: language === "pt"
														? "150px"
														: ""
									}}
									onClick={() => {
										setProgress("selectClient");
										setSelectClient(true);
									}}
								/>
							)}
							<Button
								title={
									progress === "sale"
										? t("newSaleRecordModal.submitAction")
										: t("newSaleRecordModal.nextAction")
								}
								size="lg"
								variant="confirmation-solid"
								onClick={handleOnSubmit}
								disabled={progress === "selectClient"}
							/>
							<Button
								title={t("newSaleRecordModal.cancelAction")}
								size="lg"
								variant="cancellation"
								onClick={() =>
									clientName
										? setShowConfirmModal(true)
										: handleOnClose("cancel")
								}
							/>
						</div>
					</Modal.body.actions>
				</Modal.body>
			</Modal.root>
			{showConfirmModal && clientName && (
				<ConfirmModal
					buttonWidth="68px"
					onConfirm={() => handleOnClose("cancel")}
					title={t("newSaleRecordModal.confirmModal.title")}
					text={
						<p style={{ textAlign: "center" }}>
							<Trans>
								{t("newSaleRecordModal.confirmModal.text", {
									name: clientName
								})}
							</Trans>
						</p>
					}
					subText={t("newSaleRecordModal.confirmModal.subText")}
					confirmLabel={t("newSaleRecordModal.confirmModal.submitAction")}
					cancelLabel={t("newSaleRecordModal.confirmModal.cancelAction")}
					onCancel={() => setShowConfirmModal(false)}
				/>
			)}
			{isLoading && <LoadingProgress />}
			{!userStore.tutorials?.some((e: any) => e.id === 2) &&
				userStore.role === "distributor_user" && (
					<JoyRide
						steps={StepSalesRecord}
						run={true}
						id={2}
						backButton={true}
					/>
				)}
		</>
	);
};
