import { Button, Divider, GenericTable } from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext } from "react";
import { Upload } from "..";
import { AuthContext, SalesContext } from "../../context";
import i18n from "../../i18n/i18n";
import "./styles.scss";

type Props = {
	onEdit: () => void;
};

export const ViewSaleRecord = ({ onEdit }: Props) => {
	const { saleRecord } = useContext(SalesContext);
	const { user } = useContext(AuthContext);

	const isAdminUser = user?.role?.split("_")[1] === "administrator";
	const isComexUser = user?.role?.split("_")[0] === "comex";

	return (
		<>
			<div className="form">
				<div className="form-item">
					<label htmlFor="distributorOrder">
						{t("saleRecordModal.form.createdAtInput.label")}:
					</label>
					<span>
						{saleRecord?.createdAt
							? new Date(saleRecord.createdAt).toLocaleDateString(
									i18n.language,
									{
										timeZone: "UTC"
									}
								)
							: "-"}
					</span>
				</div>
				<div className="form-item">
					<label htmlFor="saleDate">
						{t("saleRecordModal.form.saleDateInput.label")}:
					</label>
					<span>
						{saleRecord?.saleDate
							? new Date(`${saleRecord.saleDate}`).toLocaleDateString(
									i18n.language,
									{
										timeZone: "UTC"
									}
								)
							: "-"}
					</span>
				</div>
				<div className="form-item">
					<label htmlFor="distributorOrder">
						{t("saleRecordModal.form.distributorOrderInput.label")}:
					</label>
					<span>{saleRecord?.distributorOrder || "-"}</span>
				</div>
				<div className="form-item">
					<label htmlFor="type">
						{t("saleRecordModal.form.typeInput.label")}:
					</label>
					<span>
						{t(
							`saleRecordModal.form.clientTypeLabel.typeOptions.${saleRecord?.client?.type}`
						) || "-"}
					</span>
				</div>
				<div className="form-item">
					<label htmlFor="name">
						{t("saleRecordModal.form.nameInput.label")}:
					</label>
					<span>{saleRecord?.client?.name || "-"}</span>
				</div>
				<div className="form-item">
					<label htmlFor="email">
						{t("saleRecordModal.form.emailInput.label")}:
					</label>
					<span>{saleRecord?.client?.email || "-"}</span>
				</div>
				<div className="form-item">
					<label htmlFor="client.phone">
						{t("saleRecordModal.form.phoneInput.label")}:
					</label>
					<span>{saleRecord?.client?.phone || "-"}</span>
				</div>
				<div className="form-item">
					<label htmlFor="city">
						{t("saleRecordModal.form.cityInput.label")}:
					</label>
					<span>{saleRecord?.client?.city || "-"}</span>
				</div>
				<div className="form-item">
					<label htmlFor="country">
						{t("saleRecordModal.form.countryInput.label")}:
					</label>
					<span>{saleRecord?.client?.country?.name || "-"}</span>
				</div>
				<div className="form-item" />
				<div className="form-item observation">
					<label htmlFor="observation">
						{t("saleRecordModal.form.observationInput.label")}:
					</label>
					<span>{saleRecord?.observation || "-"}</span>
				</div>
				<div className="form-item">
					<label htmlFor="observation">
						{t("saleRecordModal.form.salesAttachment.label")}:
					</label>
					<Upload.view listFiles={saleRecord?.files} />
				</div>
			</div>
			<Divider title={t("saleRecordModal.divider")} side="left" />
			<div className="equipment-list">
				<GenericTable
					data={saleRecord?.equipments?.map((equipment) => ({
						...equipment,
						serialNumber: equipment?.serialNumber || "-",
						description:
							i18n.language === "en"
								? equipment.equipmentModel.descriptionEn
								: i18n.language === "es"
									? equipment.equipmentModel.descriptionEs
									: equipment.equipmentModel.descriptionPt || "-",
						viewInstallationDate: equipment?.installationDate
							? new Date(equipment.installationDate).toLocaleDateString(
									i18n.language,
									{
										timeZone: "UTC"
									}
								)
							: "-"
					}))}
					columns={[
						{
							key: "serialNumber",
							header: t("saleRecordModal.tableColumns.serialNumber"),
							width: "20%"
						},
						{
							key: "description",
							header: t("saleRecordModal.tableColumns.description"),
							width: "60%"
						},
						{
							key: "viewInstallationDate",
							header: t("saleRecordModal.tableColumns.installationDate"),
							width: "20%"
						}
					]}
				/>
			</div>
			{(isComexUser || isAdminUser) && (
				<div className="actions">
					<Button
						title={t("saleRecordModal.editAction")}
						size="lg"
						variant="outline"
						onClick={onEdit}
					/>
				</div>
			)}
		</>
	);
};
