import { Button } from "@praticabr/ppsa";
import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

interface Props {
	sendMail: () => void;
}

export const ResetAccountPage = ({ sendMail }: Props) => {
	const { t } = useTranslation();
	return (
		<div className="reset-pass-form">
			<div className="reset-pass-completed">
				<h4>{t("loginPage.lockedAccountText1")}</h4>
				<h4>{t("loginPage.lockedAccountText2")}</h4>
				<Button
					size="lg"
					title={t("loginPage.lockedAccountButton")}
					variant="standard"
					onClick={() => sendMail()}
				/>
			</div>
		</div>
	);
};
