import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

export const ResetAccountPageSuccess = () => {
	const { t } = useTranslation();
	return (
		<div className="reset-pass-form">
			<div className="reset-pass-completed">
				<h4>
					{t("loginPage.sendedEmailLockedText1")}
					<br />
					{t("loginPage.sendedEmailLockedText2")}
				</h4>
			</div>
		</div>
	);
};
