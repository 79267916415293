import { Button, ConfirmModal, LoadingProgress, Modal } from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ClientContext, TechnicalSupportsContext } from "../../context";
import { ReplacedPartForm, UploadFile } from "../../models";
import { AddService } from "../../models/usecases/add-service";
import { deleteUpload } from "../../services";
import { store } from "../../store";
import { StepServices } from "../../utils/JoyrideSteps";
import { JoyRide } from "../JoyRide";
import { ServiceClientInfo } from "./ServiceClientInfo";
import { ServiceItensInfo } from "./ServiceItensInfo";
import { ServicePartsInfo } from "./ServicePartsInfo";
import { ServiceTechnicalInfo } from "./ServiceTechnicalInfo";
import "./styles.scss";

function isObjectEmpty(obj: object): boolean {
	return Object.keys(obj).length === 0;
}

type Props = {
	onClose: () => void;
};

export const IncludeServiceModal = ({ onClose }: Props) => {
	const [progress, setProgress] = useState<
		"itens" | "client" | "technical" | "parts"
	>("itens");
	const [steps, setSteps] = useState<string[]>([]);
	const [selectedItens, setSelectedEquipments] = useState<any[]>([]);
	const [valuePartsField, setValuePartsField] = useState<any[]>([]);

	const [fileDeleted, setFileDeleted] = useState<any[]>([]);

	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const { handleNewTechnicalSupport, isLoading, onSearchParams } = useContext(
		TechnicalSupportsContext
	);
	const { onGetClientById } = useContext(ClientContext);

	const userStore = store.getState().auth;

	const { t } = useTranslation();

	const clientForm = useForm<Pick<AddService.Params, "client">>();
	const clientName = clientForm.watch("client")?.name || "";
	const clientEmail = clientForm.watch("client")?.email || "";

	const itensForm = useForm<Pick<AddService.Params, "equipments">>();
	const itensFieldArray = useFieldArray({
		control: itensForm.control,
		name: "equipments"
	});

	const partsForm = useForm<ReplacedPartForm>();
	const partsFieldArray = useFieldArray({
		control: partsForm.control,
		name: "replacedParts"
	});

	const technicalSupportsForm =
		useForm<Pick<AddService.Params, "technicalSupports">>();
	const technicalSupportsFieldArray = useFieldArray({
		control: technicalSupportsForm.control,
		name: "technicalSupports"
	});

	const { fields } = itensFieldArray;

	const filesForm = useForm<UploadFile, "files">();
	const filesFieldArray = useFieldArray({
		control: filesForm.control,
		name: "files"
	});

	useEffect(() => {
		itensForm.setValue(
			"equipments",
			selectedItens.map((item) => {
				const newListEquipment = {
					...item,
					equipmentId: item.id
				};
				return newListEquipment;
			})
		);
	}, [selectedItens]);

	const handleOnSubmit = async () => {
		if (progress === "itens") {
			if (selectedItens.length === 0) {
				toast.error(t("newServiceModal.toast.itemsError"));
				return;
			}
			setProgress("client");
			if (!steps.includes("client")) {
				setSteps((step) => [...step, "itens", "client"]);
			}

			return;
		}

		if (progress === "client") {
			await clientForm.handleSubmit(() => {})();
			const clientErrors = !isObjectEmpty(clientForm.formState.errors);
			if (clientErrors) {
				return;
			}
			setProgress("technical");
			if (!steps.includes("technical")) {
				setSteps((step) => [...step, "client", "technical"]);
			}
			return;
		}

		await technicalSupportsForm.handleSubmit(() => {})();
		await itensForm.handleSubmit(() => {})();
		const itensErrors = !isObjectEmpty(itensForm.formState.errors);
		await partsForm.handleSubmit(() => {})();
		const partsErrors = !isObjectEmpty(partsForm.formState.errors);

		if (progress === "parts" || progress === "technical") {
			technicalSupportsForm
				.getValues()
				.technicalSupports.forEach((item, index) => {
					progress === "parts" &&
						partsForm.getValues().replacedParts.forEach((part) => {
							if (!valuePartsField[index]?.length) {
								toast.error(t("newServiceModal.toast.partError"));
							}
							if (index === part.item) {
								if (valuePartsField.length) {
									for (let i = 0; i < valuePartsField[index].length; i++) {
										if (valuePartsField[index][i].code === part.code) {
											if (
												valuePartsField[index][i].amount === 0 ||
												!valuePartsField[index][i].amount ||
												valuePartsField[index][i].amount === "-"
											) {
												return partsForm.setError(
													`replacedParts.${part.index}.amount`,
													{
														type: "required"
													}
												);
											}
											if (valuePartsField[index][i].partReplacement === "") {
												return partsForm.setError(
													`replacedParts.${part.index}.partReplacement`,
													{
														type: "required"
													}
												);
											}
											if (!valuePartsField[index][i].reportedDefect) {
												return partsForm.setError(
													`replacedParts.${part.index}.reportedDefect`,
													{
														type: "required"
													}
												);
											} else {
												part.amount = valuePartsField[index][i].amount;
												part.reportedDefect =
													valuePartsField[index][i].reportedDefect;
												part.partReplacement =
													valuePartsField[index][i].partReplacement;
												partsForm.clearErrors([
													`replacedParts.${part.index}.amount`,
													`replacedParts.${part.index}.reportedDefect`,
													`replacedParts.${part.index}.partReplacement`
												]);
											}
										}
									}
								} else {
									partsForm.setError(`replacedParts.${part.item}.amount`, {
										type: "required"
									});
									partsForm.setError(
										`replacedParts.${part.item}.reportedDefect`,
										{
											type: "required"
										}
									);
									partsForm.setError(
										`replacedParts.${part.item}.partReplacement`,
										{
											type: "required"
										}
									);
									return;
								}

								if (!Array.isArray(item.replacedParts)) {
									item.replacedParts = [];
								}
								const existingPartIndex = item.replacedParts.findIndex(
									(existingPart: any) => existingPart.id === part.id
								);

								if (existingPartIndex !== -1) {
									item.replacedParts[existingPartIndex] = part;
								} else {
									item.replacedParts.push(part);
								}
							}
						});

					progress === "technical" &&
						filesFieldArray.fields.forEach((file) => {
							if (index === file.item) {
								if (!Array.isArray(item.files)) {
									item.files = [];
								}

								const existingFileIndex = item.files.findIndex(
									(existingFile: any) => existingFile.name === file.name
								);

								if (existingFileIndex !== -1) {
									item.files[existingFileIndex] = file;
								} else {
									item.files.push(file);
								}
							}
						});
				});
		}

		if (progress === "technical") {
			technicalSupportsForm
				.getValues()
				.technicalSupports.forEach((item, index) => {
					if (
						!item.technician ||
						!item.technician.email ||
						!item.technician.contact ||
						!item.technician.phone ||
						!item.servicePerformed ||
						!item.files ||
						!item.defectId
					) {
						!item.technician?.email &&
							technicalSupportsForm.setError(
								`technicalSupports.${index}.technician.email`,
								{
									type: "required",
									message: t(
										"newServiceModal.technicalContain.technicalEmail.requiredAlert"
									)
								}
							);
						!item.technician?.contact &&
							technicalSupportsForm.setError(
								`technicalSupports.${index}.technician.contact`,
								{
									type: "required",
									message: t(
										"newServiceModal.technicalContain.technicalName.requiredAlert"
									)
								}
							);
						!item.technician?.phone &&
							technicalSupportsForm.setError(
								`technicalSupports.${index}.technician.phone`,
								{
									type: "required",
									message: t(
										"newServiceModal.technicalContain.technicalPhone.requiredAlert"
									)
								}
							);
						!item.servicePerformed &&
							technicalSupportsForm.setError(
								`technicalSupports.${index}.servicePerformed`,
								{
									type: "required"
								}
							);
						!item.defectId &&
							technicalSupportsForm.setError(
								`technicalSupports.${index}.defectId`,
								{
									type: "required",
									message: t(
										"newServiceModal.technicalContain.technicalDefect.requiredAlert"
									)
								}
							);

						if (!item.files) {
							technicalSupportsForm.setError(
								`technicalSupports.${index}.files`,
								{
									type: "required"
								}
							);
							toast.error(t("newServiceModal.toast.filesError"));
						}
					} else {
						technicalSupportsForm.clearErrors(`technicalSupports.${index}`);
					}
				});
		}

		if (progress === "parts") {
			technicalSupportsForm
				.getValues()
				.technicalSupports.forEach((item, index) => {
					if (!item.replacedParts) {
						technicalSupportsForm.setError(
							`technicalSupports.${index}.replacedParts`,
							{
								type: "required",
								message: t(
									"newServiceModal.technicalContain.technicalParts.requiredAlert"
								)
							}
						);
					} else {
						technicalSupportsForm.clearErrors(
							`technicalSupports.${index}.replacedParts`
						);
					}
				});
		}

		const technicalSupportsErrors =
			technicalSupportsForm.getFieldState("technicalSupports").error;

		if (!technicalSupportsErrors) {
			if (progress === "technical") {
				setProgress("parts");
				if (!steps.includes("parts")) {
					setSteps((step) => [...step, "parts"]);
				}
				return;
			}
		}

		if (fileDeleted.length > 0) {
			fileDeleted.forEach((file) => {
				deleteUpload(file);
			});
		}

		if (
			itensErrors ||
			technicalSupportsErrors ||
			partsErrors ||
			!valuePartsField.length
		) {
			console.log(
				"itensErrors: ",
				itensErrors,
				itensForm.formState.errors,
				"technicalSupportsErrors: ",
				technicalSupportsErrors,
				technicalSupportsForm.formState.errors,
				"partsErrors: ",
				partsErrors,
				partsForm.formState.errors
			);
			technicalSupportsErrors &&
				toast.error(t("newServiceModal.toast.technicalInfoErro"));
		} else {
			const { report, ...rest } = clientForm.getValues().client;
			const data = {
				client: rest,
				technicalSupports: technicalSupportsForm
					.getValues()
					.technicalSupports.map((item: any) => ({
						clientReport: report || "",
						equipmentId: item.equipmentId,
						technician: item.technician,
						servicePerformed: item.servicePerformed,
						defectId: item.defectId,
						files: item.files.map((file: any) => ({
							extension: file.type,
							description: file.description,
							url: file.url
						})),
						replacedParts: item.replacedParts.map((part: any) => ({
							amount: part.amount,
							historicalValue: part.value,
							partId: part.id,
							reportedDefect: part.reportedDefect,
							partReplacement: part.partReplacement === "true"
						}))
					}))
			};
			handleNewTechnicalSupport(data, () => handleOnClose("submit"));
		}
	};

	const handleOnClose = (whence: string) => {
		whence !== "submit" && clearFields();
		clientForm.reset();
		itensForm.reset();
		technicalSupportsForm.reset();
		partsForm.reset();
		onSearchParams("");
		onClose();
	};

	const clearFields = () => {
		filesFieldArray.fields.forEach((field) => {
			deleteUpload(field);
		});
	};

	const onChangeCompleteSteps = (
		item: "client" | "itens" | "technical" | "parts"
	) => {
		if (steps.includes(item)) {
			setProgress(item);
		}
	};

	const onClientChange = (id: number) => {
		onGetClientById(id, (data: any) => {
			clientForm.setValue("client", data.client);
		});
	};

	const mainContainer = (
		progress: "itens" | "client" | "technical" | "parts"
	) => {
		switch (progress) {
			case "itens":
				return (
					<ServiceItensInfo
						selectedItens={selectedItens}
						setSelectedEquipments={setSelectedEquipments}
						technicalSupportsField={technicalSupportsFieldArray}
						{...{ onClientChange }}
					/>
				);
			case "client":
				return <ServiceClientInfo clientForm={clientForm} />;
			case "technical":
				return (
					<ServiceTechnicalInfo
						client={{ name: clientName, email: clientEmail }}
						selectedItens={fields}
						setSelectedEquipments={setSelectedEquipments}
						technicalSupportsForm={technicalSupportsForm}
						filesForm={filesForm}
						fieldFiles={filesFieldArray}
						technicalSupportsField={technicalSupportsFieldArray}
						{...{ setFileDeleted }}
					/>
				);
			case "parts":
				return (
					<ServicePartsInfo
						client={{ name: clientName, email: clientEmail }}
						selectedItens={fields}
						{...{ setFileDeleted }}
						partsForm={partsForm}
						partsField={partsFieldArray}
						technicalSupportsForm={technicalSupportsForm}
						valuePartsField={valuePartsField}
						setValuePartsField={setValuePartsField}
					/>
				);
			default:
				return (
					<ServiceItensInfo
						selectedItens={selectedItens}
						setSelectedEquipments={setSelectedEquipments}
						technicalSupportsField={technicalSupportsFieldArray}
						{...{ onClientChange }}
					/>
				);
		}
	};

	return (
		<>
			<Modal.root>
				<Modal.header
					title={t("newServiceModal.title")}
					onClose={() => handleOnClose("close")}
				/>
				<Modal.body>
					<Modal.body.main>
						<div className="modal-include-service">
							<div className="progress">
								<div
									className="progress-item itens"
									onClick={() => onChangeCompleteSteps("itens")}
								>
									<label htmlFor="itens">
										{t("newServiceModal.progressBar.itens")}
									</label>

									<div
										id="itens"
										className={
											progress === "itens"
												? "active-first-step"
												: "completed-first-step"
										}
									/>
								</div>
								<div
									className="progress-item client"
									onClick={() => onChangeCompleteSteps("client")}
								>
									<label htmlFor="client">
										{t("newServiceModal.progressBar.client")}
									</label>
									<div
										id="client"
										className={
											progress === "client" ||
											progress === "technical" ||
											progress === "parts"
												? progress === "client"
													? "active-step"
													: "completed-step"
												: "uncompleted-step"
										}
									/>
								</div>

								<div
									className="progress-item technical"
									onClick={() => onChangeCompleteSteps("technical")}
								>
									<label htmlFor="technical">
										{t("newServiceModal.progressBar.technical")}
									</label>
									<div
										id="technical"
										className={
											progress === "technical" || progress === "parts"
												? progress === "technical"
													? "active-step"
													: "completed-step"
												: "uncompleted-step"
										}
									/>
								</div>
								<div
									className="progress-item parts"
									onClick={() => onChangeCompleteSteps("parts")}
								>
									<label htmlFor="parts">
										{t("newServiceModal.progressBar.parts")}
									</label>
									<div
										id="parts"
										className={
											progress === "parts"
												? progress === "parts"
													? "active-last-step"
													: "completed-last-step"
												: "uncompleted-last-step"
										}
									/>
								</div>
							</div>
							{mainContainer(progress)}
						</div>
					</Modal.body.main>
					<Modal.body.actions>
						<div className="actions">
							<Button
								title={
									progress === "parts"
										? t("newServiceModal.submitAction")
										: t("newServiceModal.nextAction")
								}
								size="lg"
								variant="confirmation-solid"
								onClick={handleOnSubmit}
							/>
							<Button
								title={t("newServiceModal.cancelAction")}
								size="lg"
								variant="cancellation"
								onClick={() =>
									clientName
										? setShowConfirmModal(true)
										: handleOnClose("cancel")
								}
							/>
						</div>
					</Modal.body.actions>
				</Modal.body>
			</Modal.root>
			{showConfirmModal && clientName && (
				<ConfirmModal
					buttonWidth="68px"
					onConfirm={() => handleOnClose("cancel")}
					title={t("newServiceModal.confirmModal.title")}
					text={
						<p style={{ textAlign: "center" }}>
							<Trans>
								{t("newServiceModal.confirmModal.text", {
									name: clientName
								})}
							</Trans>
						</p>
					}
					subText={t("newServiceModal.confirmModal.subText")}
					confirmLabel={t("newServiceModal.confirmModal.submitAction")}
					cancelLabel={t("newServiceModal.confirmModal.cancelAction")}
					onCancel={() => setShowConfirmModal(false)}
				/>
			)}
			{isLoading && <LoadingProgress />}
			{!userStore.tutorials?.some((e: any) => e.id === 3) &&
				userStore.role === "distributor_user" && (
					<JoyRide steps={StepServices} run={true} id={3} backButton={true} />
				)}
		</>
	);
};
