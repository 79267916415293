import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CreditConsultation } from "../../components/Dashboard/CreditConsultation";
import { Equipments } from "../../components/Dashboard/Equipments";
import { FinancialAnalysis } from "../../components/Dashboard/FinancialAnalysis";
import { PartsRegistration } from "../../components/Dashboard/PartsRegistration";
import { TotalRegister } from "../../components/Dashboard/TotalRegister";
import { JoyRide } from "../../components/JoyRide";
import { DashboardContext } from "../../context";
import { store } from "../../store";
import { StepsMainFunctions } from "../../utils/JoyrideSteps";
import "./styles.scss";

export const DashboardPage = () => {
	const { list, onGetDashboardList } = useContext(DashboardContext);

	const { i18n } = useTranslation();

	const userStore = store.getState().auth;

	const navigate = useNavigate();

	useEffect(() => {
		if (userStore.role === "distributor_user") {
			onGetDashboardList();
		} else {
			navigate(`/${userStore.language}/sales`);
		}
	}, [i18n.language]);

	return (
		<>
			<article className="dashboard-page">
				<div className="dashboard-container">
					<div className="dashboard-items">
						<CreditConsultation credits={list?.credits} />
						<TotalRegister
							sales={list?.sales}
							technicalSupport={list?.technicalSupport}
						/>
					</div>
					<div className="dashboard-items">
						<FinancialAnalysis technicalSupport={list?.technicalSupport} />
						<PartsRegistration list={list?.requestedParts} />
					</div>
					<div className="dashboard-items">
						<Equipments list={list?.equipments} />
					</div>
				</div>
			</article>
			{!userStore.tutorials?.some((e: any) => e.id === 1) &&
				userStore.role === "distributor_user" && (
					<JoyRide steps={StepsMainFunctions} run={true} id={1} />
				)}
		</>
	);
};
