import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
	technicalSupport?: {
		lastQuarterAmountRecorded: number;
		penultimateQuarterAmountRecorded: number;
	};
	sales?: {
		lastQuarterAmountRecorded: number;
		penultimateQuarterAmountRecorded: number;
	};
};

export const TotalRegister = ({ technicalSupport, sales }: Props) => {
	const navigate = useNavigate();

	const { t, i18n } = useTranslation();
	const calculePercentage = (value: number, total: number) => {
		if (total === 0) {
			return 0;
		}
		const percentage = (value / total) * 100;
		if (isNaN(percentage)) {
			return 0;
		}
		return percentage;
	};

	return (
		<div className="total-register">
			<div
				className="item"
				onClick={() => {
					navigate(`/${i18n.language}/sales`);
				}}
			>
				<p className="item-title">
					{t("dashboardPage.totalRegister.salesRegister.title.row1")} <br />
					{t("dashboardPage.totalRegister.salesRegister.title.row2")}
				</p>
				<h1 className="item-value">{sales?.lastQuarterAmountRecorded || 0}</h1>
				<p className="item-percentage">
					{t("dashboardPage.totalRegister.salesRegister.percentText", {
						val: calculePercentage(
							sales?.penultimateQuarterAmountRecorded || 0,
							sales?.lastQuarterAmountRecorded || 0
						).toPrecision(2)
					})}
				</p>
			</div>
			<div
				className="item"
				onClick={() => {
					navigate(`/${i18n.language}/services`);
				}}
			>
				<p className="item-title">
					{t("dashboardPage.totalRegister.technicalSupport.title.row1")} <br />
					{t("dashboardPage.totalRegister.technicalSupport.title.row2")}
				</p>
				<h1 className="item-value">
					{technicalSupport?.lastQuarterAmountRecorded || 0}
				</h1>
				<p className="item-percentage">
					{t("dashboardPage.totalRegister.technicalSupport.percentText", {
						val: calculePercentage(
							technicalSupport?.penultimateQuarterAmountRecorded || 0,
							technicalSupport?.lastQuarterAmountRecorded || 0
						).toPrecision(2)
					})}
				</p>
			</div>
		</div>
	);
};
