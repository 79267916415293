import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React from "react";
import { Pie } from "react-chartjs-2";
import "./styles.scss";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
	technicalSupport?: {
		approvedValue: number;
		requestedValue: number;
	};
};

export const FinancialAnalysis = ({ technicalSupport }: Props) => {
	ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false
			},
			title: {
				display: false
			}
		},
		animation: {
			duration: 3000
		}
	};

	const calculatePercentage = (value: number, total: number) => {
		const percentage = (value / total) * 100;
		if (isNaN(percentage)) {
			return 0;
		}
		return percentage;
	};

	const data = {
		labels: [
			t("dashboardPage.financialAnalysis.tooltips.granted"),
			t("dashboardPage.financialAnalysis.tooltips.requested")
		],
		datasets: [
			{
				data: [
					calculatePercentage(
						technicalSupport?.approvedValue || 0,
						technicalSupport?.requestedValue || 0
					).toPrecision(4),
					(
						100 -
						calculatePercentage(
							technicalSupport?.approvedValue || 0,
							technicalSupport?.requestedValue || 0
						)
					).toPrecision(4)
				],
				datalabels: {
					labels: {
						title: {
							color: "#F8F9FA",
							font: { size: 16, weight: "bold" }
						}
					},
					title: {
						color: "#F8F9FA"
					},
					formatter: (value: number) => {
						return value > 0 ? `${value}%` : "";
					}
				},
				backgroundColor: ["#A6CEE3", "#134997"],
				borderWidth: 0,
				color: ["#F8F9FA", "#134997"]
			}
		]
	};

	// Gere rótulos para cada segmento com seus respectivos valores
	const labelsWithValue = data.labels.map((label, index) => {
		const value = data.datasets[0].data[index];
		return `${label}: ${value}`;
	});

	return (
		<div
			className="financial-analysis"
			onClick={() => {
				navigate(`/${i18n.language}/credits`);
			}}
		>
			<h1> {t("dashboardPage.financialAnalysis.title")}</h1>
			<div className="graphic">
				<div className="graphic-text">
					<span>
						<p>
							{t("dashboardPage.financialAnalysis.requestedValue.row1")} <br />
							{t("dashboardPage.financialAnalysis.requestedValue.row2")}
						</p>
						<p>
							{Number(
								technicalSupport?.requestedValue.toFixed(2) || 0
							).toLocaleString("en-US", {
								style: "currency",
								currency: "USD"
							})}{" "}
							USD
						</p>
					</span>
				</div>
				<div className="graphic-divisor" />
				<div className="graphic-content">
					<Pie
						className="pie"
						data={{ labels: labelsWithValue, datasets: data.datasets as any }}
						options={options}
					/>
				</div>
				<div className="graphic-divisor" />
				<div className="graphic-text">
					<span>
						<p>
							{t("dashboardPage.financialAnalysis.approvedValue.row1")} <br />
							{t("dashboardPage.financialAnalysis.approvedValue.row2")}
						</p>
						<p>
							{Number(
								technicalSupport?.approvedValue.toFixed(2) || 0
							).toLocaleString("en-US", {
								style: "currency",
								currency: "USD"
							})}{" "}
							USD
						</p>
					</span>
				</div>
			</div>
		</div>
	);
};
