import React, { ReactNode, createContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import i18n from "../i18n/i18n";
import { GetCreditDetails } from "../models/usecases/get-credit-details";
import { GetCredits } from "../models/usecases/get-credits";
import { getCreditDetails, getCreditList } from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const CreditContext = createContext({
	list: {} as GetCredits.Return | undefined,
	isLoading: false,
	creditDetailLoading: false,
	limit: {} as number,
	total: {} as number,
	offset: {} as number,
	creditDetails: [] as GetCreditDetails.Return,
	onSearchParams: (searchTerm: string) => {},
	onPageChange: (page: number) => {},
	onLegendChange: (legend: string) => {},
	onGetCreditList: (status?: string) => {},
	onGetCreditDetails: (creditId: string, distributorCode: string) => {},
	onClearCreditDetails: () => {},
	handleExportXLSX: () => {}
});

const defaultParams = defaultListParams();

export const CreditProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [creditDetailLoading, setCreditDetailLoading] =
		useState<boolean>(false);
	const [creditsList, setCreditsList] = useState<GetCredits.Return>();
	const [creditDetails, setCreditDetails] = useState<GetCreditDetails.Return>(
		[] as GetCreditDetails.Return
	);

	const listParams = useRef<ListParams>(defaultParams);

	const { t } = useTranslation();

	const handleGetCreditList = async (status?: string) => {
		try {
			const { limit, offset, term, filter } = { ...listParams.current };
			const currentPage = offset - 1;
			setIsLoading(true);

			const response = await getCreditList({
				limit,
				offset: currentPage,
				term: term || "",
				status: filter || ""
			});
			updateParams(Math.ceil(response.data.total / limit), "total");
			setCreditsList(response.data);
		} catch (error) {
			toast.error(t("creditContext.getCreditListsError"));
		} finally {
			setIsLoading(false);
		}
	};

	const onSearchParams = (term: string) => {
		updateParams(term, "search");
		handleGetCreditList();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		handleGetCreditList();
	};

	const onLegendChange = (legend: string) => {
		updateParams(legend, "filter");
		handleGetCreditList();
	};

	const onGetCreditDetails = (creditId: string, distributorCode: string) => {
		setCreditDetailLoading(true);
		getCreditDetails(creditId, distributorCode)
			.then((response) => {
				setCreditDetails(response.data);
			})
			.catch((err) => {
				console.log("Erro: ", err);
				toast.error("Erro ao buscar baixas de crédito");
			})
			.finally(() => setCreditDetailLoading(false));
	};

	const onClearCreditDetails = () => {
		setCreditDetails([]);
	};

	const handleExportXLSX = async () => {
		try {
			setIsLoading(true);
			const { term, filter } = listParams?.current;

			const response = await getCreditList({
				limit: 999999,
				offset: 0,
				term,
				status: filter
			});

			const { credits } = response?.data;

			const data = credits?.map((credit) => ({
				[t("creditContext.headerXLSX.support")]:
					credit?.approval?.technicalSupport?.id || "-",
				[t("creditContext.headerXLSX.registrationDate")]: new Date(
					credit?.createdAt
				)?.toLocaleDateString(i18n?.language, {
					timeZone: "UTC"
				}),
				[t("creditContext.headerXLSX.distributor")]:
					`${credit?.distributor?.codeProtheus || ""} - ${
						credit?.distributor?.name || ""
					}`,
				[t("creditContext.headerXLSX.client")]:
					credit?.approval?.technicalSupport?.equipment?.salesRecord?.client
						?.name || "-",
				[t("creditContext.headerXLSX.observation")]: credit?.observation || "-",
				[t("creditContext.headerXLSX.value")]: credit?.value,
				[t("creditContext.headerXLSX.balance")]:
					credit?.balance <= 1 ? 0 : credit?.balance
			}));

			const worksheet = XLSX?.utils?.json_to_sheet(data);
			const workbook = XLSX?.utils?.book_new();
			XLSX?.utils?.book_append_sheet(
				workbook,
				worksheet,
				t("creditContext.headerXLSX.sheetName")
			);
			XLSX?.writeFile(
				workbook,
				`${t("creditContext.headerXLSX.filename")}_${new Date().toLocaleDateString(
					i18n?.language,
					{ timeZone: "UTC" }
				)}.xlsx`
			);

			toast.success(t("creditContext.headerXLSX.exportSuccess"));
		} catch (error) {
			console.error("Error: ", error);
			toast.error(t("creditContext.headerXLSX.exportError"));
		} finally {
			setIsLoading(false);
		}
	};

	const value = {
		list: creditsList,
		...listParams.current,
		isLoading,
		onPageChange,
		onSearchParams,
		onLegendChange,
		onGetCreditList: handleGetCreditList,
		onGetCreditDetails,
		creditDetails,
		onClearCreditDetails,
		creditDetailLoading,
		handleExportXLSX
	};

	return (
		<CreditContext.Provider value={value}>{children}</CreditContext.Provider>
	);
};
