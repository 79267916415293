import { AuthenticateRoot } from "./AuthenticateRoot";
import { LoginFormContain } from "./LoginFormContain";
import { NewPasswordContain } from "./NewPasswordContain";
import { ResetAccountPage } from "./RecoverAccountPage";
import { ResetAccountPageSuccess } from "./RecoverAccountPageSuccess";
import { RecoverPasswordContain } from "./RecoverPasswordContain";
import { ResetPasswordSuccessContain } from "./ResetPasswordSuccessContain";

export const Authenticate = {
	root: AuthenticateRoot,
	loginForm: LoginFormContain,
	recoverPasswordForm: RecoverPasswordContain,
	newPasswordForm: NewPasswordContain,
	resetPasswordSuccessContain: ResetPasswordSuccessContain,
	recoverAccountPage: ResetAccountPage,
	recoverAccountPageSuccess: ResetAccountPageSuccess
};
